define("global-admin/multi-cluster-apps/catalog/launch/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4sDiFfwk",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"new-multi-cluster-app\",null,[[\"cancel\",\"multiClusterApp\",\"parentRoute\",\"projects\",\"clusters\",\"upgrade\",\"templateResource\",\"templateKind\",\"versionLinks\",\"versionsArray\",\"isClone\"],[[28,\"action\",[[23,0,[]],\"cancel\"],null],[24,[\"model\",\"multiClusterApp\"]],[24,[\"parentRoute\"]],[24,[\"model\",\"projects\"]],[24,[\"model\",\"clusters\"]],[24,[\"model\",\"upgradeTemplate\"]],[24,[\"model\",\"tpl\"]],[24,[\"model\",\"tplKind\"]],[24,[\"model\",\"versionLinks\"]],[24,[\"model\",\"versionsArray\"]],[24,[\"model\",\"isClone\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "global-admin/multi-cluster-apps/catalog/launch/template.hbs"
    }
  });

  _exports.default = _default;
});