define("nodes/components/driver-oci/component", ["exports", "shared/mixins/node-driver", "nodes/components/driver-oci/template"], function (_exports, _nodeDriver, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var regionMap = {
    'Mumbai': 'ap-mumbai-1',
    'Seoul': 'ap-seoul-1',
    'Tokyo': 'ap-tokyo-1',
    'Toronto': 'ca-toronto-1',
    'Frankfurt': 'eu-frankfurt-1',
    'Zurich': 'eu-zurich-1',
    'Sao Paolo': 'sa-saopaulo-1',
    'London': 'uk-london-1',
    'Ashburn': 'us-ashburn-1',
    'Phoenix': 'us-phoenix-1',
    'Amsterdam': 'eu-amsterdam-1',
    'Hyderabad': 'ap-hyderabad-1',
    'Jeddah': 'me-jeddah-1',
    'Osaka': 'ap-osaka-1',
    'Melbourne': 'ap-melbourne-1',
    'Sydney': 'ap-sydney-1',
    'Chuncheon': 'ap-chuncheon-1',
    'Montreal': 'ca-montreal-1'
  };
  var nodeShapeMap = {
    'VM.Standard1.1': 'VM.Standard1.1',
    'VM.Standard1.2': 'VM.Standard1.2',
    'VM.Standard1.4': 'VM.Standard1.4',
    'VM.Standard1.8': 'VM.Standard1.8',
    'VM.Standard1.16': 'VM.Standard1.16',
    'VM.Standard2.1': 'VM.Standard2.1',
    'VM.Standard2.2': 'VM.Standard2.2',
    'VM.Standard2.4': 'VM.Standard2.4',
    'VM.Standard2.8': 'VM.Standard2.8',
    'VM.Standard2.16': 'VM.Standard2.16',
    'VM.Standard2.24': 'VM.Standard2.24',
    'BM.Standard.E2.64': 'BM.Standard.E2.64',
    'BM.Standard2.52': 'BM.Standard2.52',
    'BM.Standard.B1.44': 'BM.Standard.B1.44',
    'BM.DenseIO2.52': 'BM.DenseIO2.52',
    'BM.HPC2.36': 'BM.HPC2.36',
    'VM.Standard.E2.1.Micro': 'VM.Standard.E2.1.Micro',
    'VM.Standard.E2.2': 'VM.Standard.E2.2',
    'VM.GPU2.1': 'VM.GPU2.1',
    'VM.GPU2.2': 'VM.GPU2.2',
    'VM.GPU3.1': 'VM.GPU3.1',
    'VM.GPU3.2': 'VM.GPU3.2',
    'VM.GPU3.4': 'VM.GPU3.4',
    'VM.GPU3.8': 'VM.GPU3.8'
  };
  var imageMap = {
    'Oracle-Linux-7.7': 'Oracle-Linux-7.7'
  };

  var _default = Ember.Component.extend(_nodeDriver.default, {
    intl: Ember.inject.service(),
    settings: Ember.inject.service(),
    layout: _template.default,
    driverName: 'oci',
    region: 'us-phoenix-1',
    nodeImage: 'Oracle-Linux-7.7',
    step: 1,
    config: Ember.computed.alias('model.ociConfig'),
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      finishAndSelectCloudCredential: function finishAndSelectCloudCredential(credential) {
        Ember.set(this, 'model.cloudCredentialId', Ember.get(credential, 'id'));
      }
    },
    regionChoices: Object.entries(regionMap).map(function (e) {
      return {
        label: e[0],
        value: e[1]
      };
    }),
    selectedRegion: Ember.computed('config.region', function () {
      var region = Ember.get(this, 'config.region');
      return region;
    }),
    adChoices: Ember.computed('config.region', function () {
      // TODO get these values dynamically from OCI API (/20160918/availabilityDomains)
      var region = Ember.get(this, 'config.region');
      var values; // 3 availability domains available

      if (region === 'uk-london-1' || region === 'us-ashburn-1' || region === 'us-phoenix-1' || region === 'eu-frankfurt-1') {
        values = {
          'AD1': 'AD-1',
          'AD2': 'AD-2',
          'AD3': 'AD-3'
        };
      } else {
        // 1 availability domain available
        values = {
          'AD1': 'AD-1'
        };
      } // get the keys


      var keys = Object.keys(values); // map the values into want you want

      var result = keys.map(function (key) {
        return {
          label: values[key],
          value: values[key]
        };
      });
      return result;
    }),
    selectedAd: Ember.computed('config.nodeAvailabilityDomain', function () {
      var ad = Ember.get(this, 'config.nodeAvailabilityDomain');
      return ad;
    }),
    nodeShapeChoices: Object.entries(nodeShapeMap).map(function (e) {
      return {
        label: e[1],
        value: e[0]
      };
    }),
    selectednodeShape: Ember.computed('config.nodeShape', function () {
      var nodeShape = Ember.get(this, 'config.nodeShape');
      return nodeShape && nodeShapeMap[nodeShape];
    }),
    imageChoices: Object.entries(imageMap).map(function (e) {
      return {
        label: e[1],
        value: e[0]
      };
    }),
    selectedImage: Ember.computed('config.nodeImage', function () {
      var nodeImage = Ember.get(this, 'config.nodeImage');
      return nodeImage && imageMap[nodeImage];
    }),
    bootstrap: function bootstrap() {
      var config = Ember.get(this, 'globalStore').createRecord({
        type: 'ociConfig',
        region: 'us-phoenix-1',
        nodeImage: 'Oracle-Linux-7.7'
      });
      Ember.set(this, 'model.ociConfig', config);
    },
    validate: function validate() {
      function getRegionIdent(ocid) {
        var start = ocid.split('.', 3).join('.').length;
        var end = ocid.split('.', 4).join('.').length;
        return ocid.substring(start + 1, end);
      } // Get generic API validation errors


      this._super();

      var errors = Ember.get(this, 'errors') || [];

      if (!Ember.get(this, 'model.name')) {
        errors.push('Name is required');
      }

      if (!Ember.get(this, 'config.region')) {
        errors.push('Specifying a oci Region is required');
      }

      if (!Ember.get(this, 'config.nodeImage')) {
        errors.push('Specifying a oci node image is required');
      }

      if (!Ember.get(this, 'config.nodeShape')) {
        errors.push('Specifying a oci node shape is required');
      }

      if (!Ember.get(this, 'config.nodeCompartmentId') || !Ember.get(this, 'config.nodeCompartmentId').startsWith('ocid1.compartment')) {
        errors.push('Specifying a valid oci node compartment is required');
      }

      if (!Ember.get(this, 'config.nodeAvailabilityDomain')) {
        errors.push('Specifying a oci node availability domain is required');
      }

      if (!Ember.get(this, 'config.vcnCompartmentId')) {
        Ember.set(this, 'config.vcnCompartmentId', Ember.get(this, 'config.nodeCompartmentId'));
      } else {
        if (!Ember.get(this, 'config.vcnCompartmentId').startsWith('ocid1.compartment')) {
          errors.push('Specifying a valid oci VCN compartment is required');
        }
      }

      if (!Ember.get(this, 'config.vcnId') || !Ember.get(this, 'config.vcnId').startsWith('ocid1.vcn')) {
        errors.push('Specifying a valid oci VCN OCID is required');
      }

      if (!Ember.get(this, 'config.subnetId') || !Ember.get(this, 'config.subnetId').startsWith('ocid1.subnet')) {
        errors.push('Specifying a valid oci subnet OCID is required');
      } // phoenix and ashburn have different region identifiers


      if (Ember.get(this, 'config.region').includes('phoenix')) {
        if (!Ember.get(this, 'config.subnetId').includes('phx') || !Ember.get(this, 'config.vcnId').includes('phx')) {
          errors.push('The VCN and subnet must reside in the same region as the compute instance');
        }
      } else if (Ember.get(this, 'config.region').includes('ashburn')) {
        if (!Ember.get(this, 'config.subnetId').includes('iad') || !Ember.get(this, 'config.vcnId').includes('iad')) {
          errors.push('The VCN and subnet must reside in the same region as the compute instance');
        }
      } else {
        if (!Ember.get(this, 'config.region').includes(getRegionIdent(Ember.get(this, 'config.subnetId'))) || !Ember.get(this, 'config.region').includes(getRegionIdent(Ember.get(this, 'config.vcnId')))) {
          errors.push('The VCN and subnet must reside in the same region as the compute instance');
        }
      } // Set the array of errors for display,
      // and return true if saving should continue.


      if (Ember.get(errors, 'length')) {
        Ember.set(this, 'errors', errors);
        return false;
      } else {
        Ember.set(this, 'errors', null);
        return true;
      }
    }
  });

  _exports.default = _default;
});